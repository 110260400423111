import React from 'react';

import Layout from '../../layout/AdminLayout';
// import CompetitiveBiddingListView from '../../views/admin/CompetitiveBiddingListView';

const ProjectList = (): React.ReactElement => {
  return <Layout>1{/* <CompetitiveBiddingListView></CompetitiveBiddingListView> */}</Layout>;
};

export default ProjectList;
